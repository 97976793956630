import { Injectable, ErrorHandler} from '@angular/core'
import * as Sentry from '@sentry/browser'
import { environment } from 'environments/environment';
 
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  
  constructor() {
    
const sentryConfiguration = environment.sentry;
const integrations = [];

if (sentryConfiguration.enableBrowserTracingIntegration) {
  integrations.push(Sentry.browserTracingIntegration());
}
if (sentryConfiguration.enableReplayIntegration) {
  integrations.push(Sentry.replayIntegration());
}
    Sentry.init({
        release: "CLIV-V1",
        environment: environment.ENV,
        dsn: "https://5027dfccf9ef8c632d03eb2fde42b4ad@o4506869003976704.ingest.us.sentry.io/4507101110206464",
        tunnel: `${environment.apiUrl}/v1/sentry/tunnel`,
        integrations: integrations,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
      if(Sentry){
          Sentry.setUser({
            id: localStorage.getItem("id"),
            name: localStorage.getItem("name"),
            role: localStorage.getItem("role"),
            email: localStorage.getItem("email"),
            location: localStorage.getItem("location"),
          })
        }
  } 
  
  handleError(error:any) {
    console.error(error);
    
    Sentry.captureException(error.originalError || error)
  }
}