import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  valueChange = new Subject<any>();
  componentUpdate = new Subject<any>();
  recordsUpdate = new Subject<any>();
  
  constructor(private router: Router) { }
}
